import type { ServersList } from "~/types/servers";
import type { ServerItem } from "~/types/servers";

import {
  type GameDetail,
  type OrderHistory,
  type OrderItemRequest,
  type Order,
  type Product,
  ResponseError,
} from "~/api_gen";

export type ModifiedProduct = { amount: number; data: Product };
export type GameData = Omit<GameDetail, "products"> & {
  products: ModifiedProduct[];
};

export type Cart = {
  [key: number]: {
    [key: number]: number;
  };
};
type CommonOrderFields = {
  products: Array<OrderItemRequest>;
  email?: string;
};

export const useProductsStore = defineStore("productsStore", () => {
  /**
   * Данные игры отображаемой на странице.
   */
  const gameData = ref<GameData | null>(null);
  const { t } = useI18n();
  const noProductsError = t("products.errors.unavailable");
  const api = useApi();
  // order form errors
  const isUserMailError = ref(false);
  const isUserIdError = ref(false);
  const isUserAvatarError = ref(false);
  const isError = ref(false);
  const errorText = ref("");

  // order form values
  const inputValueMail = ref("");
  const bulldropInputValueId = ref("");
  const moogoldInputValueId = ref("");
  const standoffInputValueId = ref("");
  const steamAccountId = ref("");
  const steamAmount = ref(0);
  const steamTopupComission = ref(0);
  const inputAvatar = ref<File | undefined>();
  const gameServerId = ref();
  const surplus = ref(0);
  const totalCostSteam = ref(0);
  const historyOrderId = ref();

  const defaultSteamAvatar = ref<null | string>(null);
  const repeatedOrderId = ref<number>();

  const orderId = ref();

  function checkGameFormFieldsFilled(resourcetype: GameDetail["resourcetype"]) {
    switch (resourcetype) {
      case "BulldropGame":
        return Boolean(bulldropInputValueId.value);
      case "SupplyGame":
        return Boolean(moogoldInputValueId.value);
      case "StandoffGame":
        return (
          Boolean(standoffInputValueId.value) && Boolean(inputAvatar.value)
        );
      case "PUBGGame":
      case "Game":
      case "Steam":
      case "Voucher":
        return true;
      default:
        exhaustiveMatchGuard(resourcetype);
    }
  }

  const isEmailFieldRequired = computed(() => {
    if (!gameData.value) {
      return false;
    }
    return !gameData.value.isAuthRequired && !userStore.userIsAuthorized;
  });

  const validateEmailField = () => {
    if (isEmailFieldRequired.value && !inputValueMail.value) {
      return false;
    }
    return true;
  };

  const isFormFieldsFilled = computed(() => {
    if (!gameData.value) {
      return false;
    }
    return (
      validateEmailField() &&
      checkGameFormFieldsFilled(gameData.value.resourcetype)
    );
  });
  /**
   * Общее кол-во заказанных товаров.
   */
  const totalAmount = computed(() => {
    if (totalCostSteam.value) {
      return 1;
    } else {
      return gameData.value?.products.reduce((a, p) => a + p.amount, 0) || 0;
    }
  });
  /**
   * Общая стоимость заказанных товаров с учетом баланса
   * в валюте текущей выбранной страны.
   * Используется для заказов через карточки товара (PUBG, ZZZ)
   */
  const totalCost = computed(() => {
    const userStore = useUserStore();
    const balance = Number(userStore.userData?.balance) || 0;
    const cost =
      gameData.value?.products.reduce(
        (a, p: ModifiedProduct) => a + p.amount * useProductPrice(p.data),
        0,
      ) || 0;
    if (balance > cost) {
      return 0;
    } else {
      return cost - balance;
    }
  });
  /**
   * Индикатор необходимости отобразить форму подтверждения заказа.
   */
  const showOrderConfirmForm = ref(false);
  /**
   * Индикатор необходимости отобразить форму ввода данных заказчика.
   */
  const showUserDataForm = ref(false);

  const userStore = useUserStore();
  const lastOrders = ref<OrderHistory[]>();
  const moogoldGameServers = ref();
  const orderIsBlocked = ref(false);

  /**
   * Получает данные игры отображаемой на странице.
   *
   * @param slug - идентификатор игры.
   * @param isShowError
   */
  async function init(slug: string, isShowError = true) {
    try {
      const data = await api.games.retrieve({ id: Number(slug) });
      const products = data.products.map((data: Product) => ({
        amount: 0,
        data,
      }));
      gameData.value = Object.assign({}, data, { products });
    } catch (error: any) {
      if (!isShowError) {
        gameData.value = null;
      } else {
        showError({ statusCode: error.response.status });
      }
    }
  }

  function restoreOrderIfExists() {
    const cart = JSON.parse(localStorage.getItem("cart") || "{}") as Cart;

    if (gameData.value && gameData.value.id in cart) {
      const cartGame = cart[gameData.value.id];

      if (gameData.value.isActive) {
        gameData.value = {
          ...gameData.value,
          products: gameData.value.products.map((p) => ({
            ...p,
            amount: p.data.isActive ? cartGame[p.data.id] : 0,
          })),
        };

        saveOrderToStorage();
      }
    }
  }

  function saveOrderToStorage() {
    if (gameData.value?.id) {
      const cart = JSON.parse(localStorage.getItem("cart") || "{}");

      localStorage.setItem(
        "cart",
        JSON.stringify({
          ...cart,
          [gameData.value.id]: Object.fromEntries(
            gameData.value.products.map((p) => [p.data.id, p.amount]),
          ),
        }),
      );
    }
  }

  function deleteOrderFromStorage() {
    try {
      localStorage.removeItem("cart");
    } catch (error: any) {
      /* empty */
    }
  }

  function deleteOrderAmounts() {
    gameData.value?.products.forEach(
      (product: ModifiedProduct) => (product.amount = 0),
    );
  }

  async function getLastOrders() {
    try {
      const data = await api.orders.list({ pageSize: 4, status: "done" });
      lastOrders.value = data.results;
    } catch (error: any) {
      /* empty */
    }
  }
  /**
   * Устанавливает данные для повторного заказа.
   *
   * @param topup - данные заказа.
   */
  async function setRepeatedOrderData(topup: OrderHistory) {
    await init(topup.game.id.toString(), false);
    if (!gameData.value) {
      const notify = useNotify();
      notify({
        text: t("products.errors.unavailable"),
        type: "error",
      });
      return;
    }
    gameData.value.products = gameData.value.products.map((product) => {
      const res = topup.orderProducts.find(
        (orderProduct) => orderProduct.product.id === product.data.id,
      );
      if (res)
        return { data: product.data, amount: res.quantity } as ModifiedProduct;
      return product;
    });
    showOrderConfirmForm.value = true;

    await setRepeatedOrderBaseData(topup);
  }

  async function setRepeatedOrderBaseData(order: OrderHistory) {
    if (order.resourcetype === "SteamOrder") {
      steamAmount.value = Number(order.total);
      steamAccountId.value = order.steamAccountId;
    }
    if (order.resourcetype === "MooGoldOrder") {
      gameServerId.value = order.moogoldServer;
      moogoldInputValueId.value = order.moogoldUserId;
    }
    if (order.resourcetype === "StandoffOrder") {
      standoffInputValueId.value = order.gameAccountId;
      defaultSteamAvatar.value = order.gameAvatar;
      historyOrderId.value = order.id;
    }
    if ("email" in order && typeof order.email === "string") {
      inputValueMail.value = order.email;
    }
  }

  /**
   * Получает список доступных серверов для игры с использование Moogold.
   *
   * @param slug - идентификатор игры.
   */
  async function getMoogoldServersList(slug: string) {
    try {
      const data = await api.orders.supplyChannelServersList({
        gameId: Number(slug),
      });

      if (data.results) {
        moogoldGameServers.value = setServersObjectForSelect(data);
        if (!gameServerId.value) {
          gameServerId.value = moogoldGameServers.value[0].value;
        }
      }
    } catch (error: any) {
      /* empty */
    }
  }

  /**
   * Чтобы переиспользовать компонент Select нужно привести данные к одному типу.
   * Ключи приходящие с бэка не позволяют этого, поэтому формируем объект вручную.
   */
  function setServersObjectForSelect(data: ServersList) {
    const updatedData = data.results.map((server: ServerItem) => {
      const { id, ...rest } = server;
      return { value: id, ...rest };
    });
    return updatedData;
  }

  /**
   * Обновляет количество заказываемого товара используя его id.
   *
   * @param productId - id товара.
   * @param newAmount - новое количество.
   */
  function updateAmountUsingId(payload: {
    productId: number;
    newAmount: number;
  }) {
    const product = gameData.value?.products.find(
      (product) => product.data.id === payload.productId,
    );
    if (product) {
      product.amount = payload.newAmount;
      saveOrderToStorage();
    }
  }

  function productsToOrderItems() {
    const moogold = gameData.value?.products.map((product) => ({
      productId: product.data.id,
      quantity: product.amount,
    })) as OrderItemRequest[];
    return moogold?.filter((product) => product.quantity !== 0);
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderMoogold(commonFields: CommonOrderFields) {
    if (orderIsBlocked.value) return;

    isUserIdError.value = !moogoldInputValueId.value;

    if (isUserIdError.value) {
      notify({
        text: t("top_up_balance.errors.user_id_is_missing"),
        type: "error",
      });
      return;
    }

    orderIsBlocked.value = true;

    try {
      const data = await api.orders.supplyChannel({
        mooGoldOrderRequest: {
          ...commonFields,
          supplyChannelServer: gameServerId.value,
          supplyChannelUserId: moogoldInputValueId.value,
        },
      });
      handleOrderSuccess(data);
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        throw error;
      }
      if (!error.response) {
        isError.value = true;
        return;
      }
      const data = await error.response.json();
      if (!data) {
        isError.value = true;
        return;
      }
      if (data.email) {
        errorText.value = data.email[0];
      } else if (data._data.supply_channel_user_id) {
        errorText.value = data.supply_channel_user_id[0];
      }
    } finally {
      orderIsBlocked.value = false;
    }
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderPubg(commonFields: CommonOrderFields) {
    if (orderIsBlocked.value) return;

    orderIsBlocked.value = true;
    try {
      const data = await api.orders.pubg({
        pUBGOrderRequest: {
          ...commonFields,
        },
      });
      handleOrderSuccess(data);
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        throw error;
      }
      const data = await error.response.json();
      if (!data) {
        isError.value = true;
        return;
      }
      if (data.email) {
        errorText.value = data.email[0];
      } else if (data.pubg_uid) {
        errorText.value = data.pubg_uid[0];
      }
    } finally {
      orderIsBlocked.value = false;
    }
  }

  async function order() {
    if (!gameData.value) {
      throw new Error("Game data is undefined");
    }
    const commonFields = {
      products: productsToOrderItems(),
      email: inputValueMail.value || undefined,
    };

    isUserMailError.value = !validateEmailField();

    if (isUserMailError.value) {
      notify({
        text: t("top_up_balance.errors.mail_is_missing"),
        type: "error",
      });
      return;
    }

    switch (gameData.value.resourcetype) {
      case "BulldropGame":
        orderBulldrop(commonFields);
        break;
      case "PUBGGame":
        orderPubg(commonFields);
        break;
      case "StandoffGame":
        orderStandoff(commonFields);
        break;
      case "SupplyGame":
        orderMoogold(commonFields);
        break;
      case "Voucher":
        orderBulldropVoucher(commonFields);
        break;
      case "Steam":
        steamOrder();
        break;
      case "Game":
        throw new Error("Game data is undefined");
      default:
        exhaustiveMatchGuard(gameData.value.resourcetype);
    }
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderBulldrop(commonFields: CommonOrderFields) {
    if (orderIsBlocked.value) return;

    isUserIdError.value = !bulldropInputValueId.value;

    if (isUserIdError.value) {
      notify({
        text: t("top_up_balance.errors.user_id_is_missing"),
        type: "error",
      });
      return;
    }

    orderIsBlocked.value = true;

    try {
      const data = await api.orders.bulldrop({
        bulldropOrderRequest: {
          ...commonFields,
          bulldropUserId: Number(bulldropInputValueId.value),
        },
      });
      handleOrderSuccess(data);
    } catch (error: any) {
      if (!error.response) {
        isError.value = true;
        return;
      }
      const data = await error.response.json();
      if (!data) {
        isError.value = true;
        return;
      }
      if (data.email) {
        errorText.value = data.email[0];
      } else if (data.bulldrop_user_id) {
        errorText.value = data.bulldrop_user_id[0];
      }
    } finally {
      orderIsBlocked.value = false;
    }
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderStandoffAvatar({
    orderId,
    gameAvatar,
  }: {
    gameAvatar: Blob | undefined;
    orderId: number;
  }) {
    const data = await api.orders.standoffAvatar({
      id: orderId,
      gameAvatar: historyOrderId.value ? undefined : gameAvatar,
      historyOrderId: historyOrderId.value,
    });

    historyOrderId.value = undefined;
    return data;
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderStandoff(commonFields: CommonOrderFields) {
    if (orderIsBlocked.value) return;

    isUserIdError.value = !standoffInputValueId.value;
    isUserAvatarError.value = !inputAvatar.value && !historyOrderId.value;

    if (isUserIdError.value) {
      notify({
        text: t("top_up_balance.errors.user_id_is_missing"),
        type: "error",
      });
      return;
    }

    if (!inputAvatar.value && !historyOrderId.value) {
      notify({
        text: t("top_up_balance.errors.avatar_is_missing"),
        type: "error",
      });
      return;
    }

    orderIsBlocked.value = true;

    try {
      const data = await api.orders.standoff({
        standoffOrderRequest: {
          ...commonFields,
          gameAccountId: standoffInputValueId.value,
          surplus: surplus.value,
        },
      });
      if (!data?.id) return;
      const avatarData = await orderStandoffAvatar({
        orderId: data?.id,
        gameAvatar: inputAvatar.value,
      });
      await handleOrderSuccess(avatarData);
      defaultSteamAvatar.value = null;
    } catch (error: any) {
      if (!error.response) {
        isError.value = true;
        return;
      }
      const data = await error.response.json();
      if (!data) {
        isError.value = true;
        return;
      }
      if (data.email) {
        errorText.value = data.email[0];
      } else if (data.game_account_id) {
        errorText.value = data.game_account_id[0];
      } else if (data.surplus) {
        errorText.value = data.surplus[0];
      } else if (data.game_avatar) {
        errorText.value = data.game_avatar[0];
      }
    } finally {
      orderIsBlocked.value = false;
    }
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderBulldropVoucher(commonFields: CommonOrderFields) {
    if (orderIsBlocked.value) return;

    orderIsBlocked.value = true;

    try {
      const data = await api.orders.voucher({
        voucherOrderRequest: {
          ...commonFields,
        },
      });
      await handleOrderSuccess(data);
    } catch (error: any) {
      if (error && error.data) {
        if (error.data.email) {
          errorText.value = error.data.email[0];
        }
      } else {
        isError.value = true;
      }
    } finally {
      orderIsBlocked.value = false;
    }
  }

  const orderStandoffSettings = () => {
    return api.orders.standoffSettingsRetrieve();
  };

  function initOrderFormValues() {
    if (!inputValueMail.value)
      inputValueMail.value = userStore.userData?.email || "";
    isUserMailError.value = false;
    isUserIdError.value = false;
    isUserAvatarError.value = false;
    isError.value = false;
    errorText.value = "";
    surplus.value = Math.floor(Math.random() * 24) / 100;
  }

  const notify = useNotify();

  async function handleOrderSuccess(order: Order) {
    showOrderConfirmForm.value = false;
    showUserDataForm.value = false;
    orderId.value = order.id;
    navigateToPayments(orderId.value);
    checkIsOrdersHistoryModal();
  }

  function navigateToPayments(orderId: number, replace: boolean = false) {
    document.documentElement.style.overflow = "";
    navigateTo({ path: `/orders/${orderId}/pay`, replace });
  }

  async function getSteamComission() {
    try {
      const data = await useApi().orders.steamSettingsRetrieve();
      steamTopupComission.value = data.commission;
    } catch (e: any) {
      console.log(e);
    }
  }

  async function steamOrder() {
    const data = await useApi().orders.steam({
      steamOrderRequest: {
        amount: steamAmount.value,
        steamAccountId: steamAccountId.value,
        email: inputValueMail.value,
      },
    });
    orderId.value = data.id;
    totalCostSteam.value = data.total;
    navigateToPayments(data.id);
  }

  const continueOrder = async (id: number) => {
    const data = await api.orders.paymentDataRetrieve({ id });
    if ("url" in data) {
      window.open(data.url);
    }
  };

  /**
   * Проверяем открыто ли окно истории заказов и если да,
   * то закрываем его.
   * Эта ф-ия нужна, чтобы когда мы повторяем заказ из истории заказов, то после
   * перехода на страницу платежей не висела модалка открытой
   */
  function checkIsOrdersHistoryModal() {
    if (userStore.showLastOrderPopup) {
      userStore.showLastOrderPopup = false;
    }
  }
  return {
    gameData,
    totalCost,
    totalAmount,
    showOrderConfirmForm,
    moogoldGameServers,
    showUserDataForm,
    lastOrders,
    noProductsError,
    orderIsBlocked,
    isUserMailError,
    isUserIdError,
    isUserAvatarError,
    isError,
    isEmailFieldRequired,
    isFormFieldsFilled,
    errorText,
    inputValueMail,
    bulldropInputValueId,
    moogoldInputValueId,
    standoffInputValueId,
    inputAvatar,
    gameServerId,
    surplus,
    orderId,
    totalCostSteam,
    steamTopupComission,
    steamAmount,
    steamAccountId,
    repeatedOrderId,
    defaultSteamAvatar,
    init,
    initOrderFormValues,
    updateAmountUsingId,
    getMoogoldServersList,
    orderMoogold,
    getLastOrders,
    setRepeatedOrderData,
    setRepeatedOrderBaseData,
    restoreOrderIfExists,
    saveOrderToStorage,
    deleteOrderFromStorage,
    deleteOrderAmounts,
    order,
    orderBulldrop,
    orderStandoff,
    orderPubg,
    orderStandoffSettings,
    orderBulldropVoucher,
    navigateToPayments,
    steamOrder,
    continueOrder,
    getSteamComission,
  };
});

// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderGame } from './OrderGame';
import {
    OrderGameFromJSON,
    OrderGameFromJSONTyped,
    OrderGameToJSON,
} from './OrderGame';
import type { OrderProduct } from './OrderProduct';
import {
    OrderProductFromJSON,
    OrderProductFromJSONTyped,
    OrderProductToJSON,
} from './OrderProduct';
import type { StatusEnum } from './StatusEnum';
import {
    StatusEnumFromJSON,
    StatusEnumFromJSONTyped,
    StatusEnumToJSON,
} from './StatusEnum';

/**
 * 
 * @export
 * @interface SteamOrderHistoryTyped
 */
export interface SteamOrderHistoryTyped {
    /**
     * 
     * @type {number}
     * @memberof SteamOrderHistoryTyped
     */
    readonly id: number;
    /**
     * 
     * @type {StatusEnum}
     * @memberof SteamOrderHistoryTyped
     */
    status: StatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof SteamOrderHistoryTyped
     */
    readonly created: Date;
    /**
     * 
     * @type {number}
     * @memberof SteamOrderHistoryTyped
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof SteamOrderHistoryTyped
     */
    totalToPay: number;
    /**
     * 
     * @type {string}
     * @memberof SteamOrderHistoryTyped
     */
    rejectReason: string;
    /**
     * 
     * @type {Array<OrderProduct>}
     * @memberof SteamOrderHistoryTyped
     */
    orderProducts: Array<OrderProduct>;
    /**
     * 
     * @type {OrderGame}
     * @memberof SteamOrderHistoryTyped
     */
    readonly game: OrderGame;
    /**
     * 
     * @type {boolean}
     * @memberof SteamOrderHistoryTyped
     */
    readonly isPaymentActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof SteamOrderHistoryTyped
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof SteamOrderHistoryTyped
     */
    steamAccountId: string;
    /**
     * 
     * @type {string}
     * @memberof SteamOrderHistoryTyped
     */
    resourcetype: string;
}

/**
 * Check if a given object implements the SteamOrderHistoryTyped interface.
 */
export function instanceOfSteamOrderHistoryTyped(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "totalToPay" in value;
    isInstance = isInstance && "rejectReason" in value;
    isInstance = isInstance && "orderProducts" in value;
    isInstance = isInstance && "game" in value;
    isInstance = isInstance && "isPaymentActive" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "steamAccountId" in value;
    isInstance = isInstance && "resourcetype" in value;

    return isInstance;
}

export function SteamOrderHistoryTypedFromJSON(json: any): SteamOrderHistoryTyped {
    return SteamOrderHistoryTypedFromJSONTyped(json, false);
}

export function SteamOrderHistoryTypedFromJSONTyped(json: any, ignoreDiscriminator: boolean): SteamOrderHistoryTyped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': StatusEnumFromJSON(json['status']),
        'created': (new Date(json['created'])),
        'total': json['total'],
        'totalToPay': json['total_to_pay'],
        'rejectReason': json['reject_reason'],
        'orderProducts': ((json['order_products'] as Array<any>).map(OrderProductFromJSON)),
        'game': OrderGameFromJSON(json['game']),
        'isPaymentActive': json['is_payment_active'],
        'email': json['email'],
        'steamAccountId': json['steam_account_id'],
        'resourcetype': json['resourcetype'],
    };
}

export function SteamOrderHistoryTypedToJSON(value?: SteamOrderHistoryTyped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': StatusEnumToJSON(value.status),
        'total': value.total,
        'total_to_pay': value.totalToPay,
        'reject_reason': value.rejectReason,
        'order_products': ((value.orderProducts as Array<any>).map(OrderProductToJSON)),
        'email': value.email,
        'steam_account_id': value.steamAccountId,
        'resourcetype': value.resourcetype,
    };
}

